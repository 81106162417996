<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-layout row wrap class="mx-2">
        <v-flex xs12 md12 class="mx-2">
          LEGENDS - (REMARKS-
          <v-icon class="mr-2" color="info">
            {{ icons.mdiSwapHorizontal }}
          </v-icon>
          ),(DELETE-
          <v-icon class="mr-2" color="error">
            {{ icons.mdiDelete }}
          </v-icon>
          )
          <h2>Total: P {{total_amount}}
            |Print
            <v-icon class="mr-2" color="success" v-if="data_items.length>0 && !is_deleting"
                    @click="print_data()">
              {{ icons.mdiPrinter }}
            </v-icon>

            <v-progress-circular color="info" indeterminate
                                 v-if="is_deleting"></v-progress-circular>
          </h2>
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <v-data-table dense
                        :headers="headers"
                        :items="data_items"
                        height="600"
          >
            <template v-slot:item.equipment_unit_quantity="{ item }">
              <tr>
                <td>
                  {{ item.equipment_unit_quantity>0?item.equipment_unit_quantity:'' }}
                </td>
              </tr>
            </template>
            <template v-slot:item.equipment_unit_price="{ item }">
              <tr>
                <td>
                  {{ item.equipment_unit_price>0?formatPrice(item.equipment_unit_price):'' }}
                </td>
              </tr>
            </template>
            <template v-slot:item.total="{ item }">
              <tr>
                <td>
                  {{
                  (item.equipment_unit_price*item.equipment_unit_quantity)>0?formatPrice(item.equipment_unit_price*item.equipment_unit_quantity):''
                  }}
                </td>
              </tr>
            </template>
            <template v-slot:item.remarks="{ item }">
              <tr>
                <td>
                  {{ item.specify_remarks!=''?item.remarks+' ('+item.specify_remarks+')':'' }}
                </td>
              </tr>
            </template>
            <template v-slot:item.action="{ item }">
              <tr v-if="item.id>0">
<!--                <td class="text-center" v-if="item.remarks!='DAMAGE'">-->
<!--                  <v-icon v-if="!is_deleting" class="mr-2" color="info" @click="transfer(item)">-->
<!--                    {{ icons.mdiSwapHorizontal }}-->
<!--                  </v-icon>-->
<!--                  <v-progress-circular color="info" indeterminate-->
<!--                                       v-if="is_deleting"></v-progress-circular>-->
<!--                </td>-->
                <td class="text-center">
                  <v-icon v-if="!is_deleting" class="mr-2" color="info" @click="transfer(item)">
                    {{ icons.mdiSwapHorizontal }}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-if="is_deleting"></v-progress-circular>
                </td>
              </tr>
            </template>
            <template v-slot:item.delete="{ item }">
              <tr v-if="item.id>0">
                <td class="text-center" v-if="item.remarks!='DAMAGE'">
                  <v-icon v-if="!is_deleting" class="mr-2" color="error" @click="delete_data(item)">
                    {{ icons.mdiDelete }}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-if="is_deleting"></v-progress-circular>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>
    <v-dialog v-model="is_change_bank_details" persistent max-width="30%">
      <TransferData :key="key" :details="details" v-on:response="on_change"></TransferData>
      <v-btn color="error" @click="is_change_bank_details = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose, mdiSwapHorizontal,
    mdiPrinter,
    mdiDelete,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import TransferData from '@/views/view_interface/inventory_monitoring/TransferData'

  const initialState = () => {
    return {
      alert: false,
      selection: '',
      total_amount: '0.00',
      is_forbidden: true,
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Trans. #', value: 'transmital_no', sortable: false},
        {text: 'Category', value: 'category.category', sortable: false},
        {text: 'Branch', value: 'branch.branch_code', sortable: false},
        {text: 'Data', value: 'equipment', sortable: false},
        {text: 'Details', value: 'equipment_brand_description', sortable: false},
        {text: 'Serial #', value: 'equipment_serial_no', sortable: false},
        {text: 'Description', value: 'equipment_model_color', sortable: false},
        {text: 'Date Procure', value: 'equipment_date_procure', sortable: false},
        {text: 'Date Released', value: 'equipment_date_release', sortable: false},
        {text: 'Price', value: 'equipment_unit_price', sortable: false},
        {text: 'Quantity', value: 'equipment_unit_quantity', sortable: false},
        {text: 'Total', value: 'total', sortable: false},
        {text: 'Remarks', value: 'remarks', sortable: false},
        {text: 'Action', value: 'action', sortable: false},
        {text: 'Delete', value: 'delete', sortable: false},
      ],

      key: 0,
      is_change_bank_details: false,
      is_deleting: false,
      details: {},
    }
  }
  export default {
    props: {
      data_items: Array,
      category_items: Array,
      branch_items: Array,
      category_id: Number,
      branch_id: Number,

      equipment: String,
      equipment_code: String,
      equipment_components: String,
    },
    components: {
      snackBarDialog,
      TransferData,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose, mdiSwapHorizontal,
          mdiPrinter,
          mdiDelete,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {

    },
    watch: {
      data_items: function () {
        var tto = 0;
        this.data_items.forEach(function (item) {
          tto += (parseFloat(item.equipment_unit_price) * parseFloat(item.equipment_unit_quantity))
        });
        this.total_amount = this.formatPrice(tto)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['name', 'employee_id', 'position', 'is_head']),
      ...mapGetters('authentication', [
        'employee_id',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory', ['delete_loaded_stocks']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      transfer(value) {
        this.key++
        this.details = value
        this.is_change_bank_details = true
      },
      delete_data(value) {
        if (confirm('PROCEED?')) {
          this.delete_loaded_stocks({
            id: value.id,
          })
          this.$emit('response', ' ')
        }
      },
      on_change(value) {
        var color = 'error'
        if (value.status === 200) {
          color = 'success'
        }
        this.change_snackbar({
          show: true,
          color: color,
          text: value.data,
        })
        this.$emit('response', ' ')
        this.is_change_bank_details = false
      },
      async print_data() {
        this.is_deleting = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printingss_ = this.printing

        widths = [30, 40, 40, 75, 60, 100, 120, 80, 50, 50, 40, 40, 40, 50]
        payments_array.push([
          {text: 'ID', alignment: 'left', style: 'main_info'},
          {text: 'Trans. #', alignment: 'left', style: 'main_info'},
          {text: 'Category', alignment: 'left', style: 'main_info'},
          {text: 'Branch', alignment: 'left', style: 'main_info'},
          {text: 'Data', alignment: 'left', style: 'main_info'},
          {text: 'Details', alignment: 'left', style: 'main_info'},
          {text: 'Serial #', alignment: 'left', style: 'main_info'},
          {text: 'Color', alignment: 'left', style: 'main_info'},
          {text: 'Date Procure', alignment: 'left', style: 'main_info'},
          {text: 'Date Released', alignment: 'left', style: 'main_info'},
          {text: 'Price', alignment: 'left', style: 'main_info'},
          {text: 'Quantity', alignment: 'left', style: 'main_info'},
          {text: 'Total', alignment: 'left', style: 'main_info'},
          {text: 'Remarks', alignment: 'left', style: 'main_info'},
        ])
        var tot_amo = this.total_amount
        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.id,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.transmital_no,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.category != null ? item.category.category : '',
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.branch != null ? item.branch.branch_code : '',
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.equipment,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.equipment_brand_description,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.equipment_serial_no,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.equipment_model_color,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.equipment_date_procure,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.equipment_date_release,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.equipment_unit_price > 0 ? (item.equipment_unit_price / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '',
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.equipment_unit_quantity > 0 ? (item.equipment_unit_quantity / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '',
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (parseFloat(item.equipment_unit_price) * parseFloat(item.equipment_unit_quantity)) > 0 ? ((parseFloat(item.equipment_unit_price) * parseFloat(item.equipment_unit_quantity)) / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '',
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.specify_remarks != '' ? item.remarks + ' (' + item.specify_remarks + ')' : '',
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: '',
              }, {
                text: '',
              },

              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },

              {
                text: '',
              },

              {
                text: '',
              },

              {
                text: '',
              },

              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: tot_amo,
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'INVENTORY PARTICULARS: ',
                  {
                    text: 'EQUIPMENTS',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'DATA: ',
                  {
                    text: this.equipment + ''
                    + this.equipment_code != '' ? ' - ' + this.equipment_code + ''
                      + (this.equipment_components != '' ? ' (' + this.equipment_components + ')' : '') : '',
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CATEGORY: ',
                  {
                    text: this.category_items[
                      this.category_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.category_id)
                      ].category,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BRANCH: ',
                  {
                    text: this.branch_items[
                      this.branch_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.branch_id)
                      ].branch_code,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LEGAL',
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'INVENTORY REPORT',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printingss_, width: 34, height: 20, style: 'logo'},
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.is_deleting = false
      }
    }
  }
</script>
